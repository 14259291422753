<template>
	<main>
		<section class="section hero-section">
			<div class="container">
				<div class="hero">
					<h1 class="hero__title">REMOTE TECH SUPPORT</h1>
				</div>
			</div>
		</section>
		<section class="section welcome-section bg-grey">
			<div class="container">
				<div class="section-content">
					<h2>Welcome to AV DEVS Remote Tech Support Services.</h2>
					<p>
						At AV DEVS, we pride ourselves on being your trusted IT partner, helping your business navigate the digital landscape with
						confidence. Our comprehensive IT support services are tailored to meet your organization's unique needs, enabling you to thrive
						in an increasingly digital world. Let us handle the technical challenges so you can focus on growing your business.
					</p>
				</div>
			</div>
		</section>
		<section class="section services-section">
			<div class="container">
				<div class="two-col-grid">
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/remote-tech-support.jpg" alt="" />
						</div>
					</div>
					<div class="col content-col">
						<div class="content">
							<Subtitle>our services</Subtitle>
							<h2 class="title">We provide you high quality remote tech support</h2>
							<p>
								We provide a wide range of IT support services, including but not limited to:
							</p>
							<ul class="list">
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Windows Servers & Internal Servers</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Documentum</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Linux Servers</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Database Management Systems</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Server Maintenance</span>
								</li>
							</ul>
							<p>
								Our dedicated team of remote tech support engineers is capable of growing from <strong>1 to 6</strong> resources, ensuring
								that we can ramp up our support capabilities as per your business requirements.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section choose-us-section">
			<div class="container">
				<div class="section-head">
					<Subtitle>Why choose us?</Subtitle>
					<h2 class="title">Why AV DEVS Tech Support?</h2>
				</div>
				<div class="reasons">
					<div class="reason">
						<div class="reason-icon">
							<div class="icon">
								<img src="../../assets/images/ticket.svg" alt="" />
							</div>
						</div>
						<div class="reason-content">
							<h3 class="reason-title">20-30 tickets</h3>
							<p>Per Technician / Day</p>
						</div>
					</div>
					<div class="reason highlight">
						<div class="reason-icon">
							<div class="icon">
								<img src="../../assets/images/badge.svg" alt="" />
							</div>
						</div>
						<div class="reason-content">
							<h3 class="reason-title">100%</h3>
							<p>We Achieve a SLA</p>
						</div>
					</div>
					<div class="reason">
						<div class="reason-icon">
							<div class="icon">
								<img src="../../assets/images/verified.svg" alt="" />
							</div>
						</div>
						<div class="reason-content">
							<h3 class="reason-title">10,000+</h3>
							<p>Resolve Tickets</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section expert-section">
			<div class="container">
				<div class="section-head">
					<Subtitle>our level of tech support</Subtitle>
					<h2 class="title">Three Levels of Expert Support</h2>
				</div>
			</div>
			<div class="levels">
				<svg viewBox="0 0 1440 417" fill="none" xmlns="http://www.w3.org/2000/svg">
					<foreignObject width="250" height="250" x="253.5" y="183.5" transform="translate(-125,-125)">
						<div class="level-content">
							<div class="level-title">Level 1</div>
							<div class="level-text">
								Our first line support team is readily available via chat, telephone, and email to resolve your basic technical issues
								quickly and efficiently.
							</div>
						</div>
					</foreignObject>
					<foreignObject width="250" height="250" x="720.5" y="233.5" transform="translate(-125,-125)">
						<div class="level-content">
							<div class="level-title">Level 2</div>
							<div class="level-text">
								Our experienced L2 support engineers have access to server back panels, allowing them to troubleshoot and resolve more
								complex issues without the need for additional tickets. They also implement preventive measures to minimize future problems.
							</div>
						</div>
					</foreignObject>
					<foreignObject width="250" height="250" x="1187.5" y="183.5" transform="translate(-125,-125)">
						<div class="level-content">
							<div class="level-title">Level 3</div>
							<div class="level-text">
								Our elite L3 support team is equipped with the skills and knowledge to tackle even the most challenging server-side
								problems. They are not only capable of resolving issues but can also set up your IT infrastructure from scratch.
							</div>
						</div>
					</foreignObject>
					<circle cx="253.5" cy="183.5" r="183" stroke="#2C3844" stroke-opacity="0.7" stroke-dasharray="10 10" />
					<circle cx="720.5" cy="233.5" r="183" stroke="#2C3844" stroke-opacity="0.7" stroke-dasharray="10 10" />
					<circle cx="1187.5" cy="183.5" r="183" stroke="#2C3844" stroke-opacity="0.7" stroke-dasharray="10 10" />
					<path d="M442 203.5C468.5 225 481 234.5 532 234.5" stroke="#2C3844" stroke-opacity="0.6" stroke-dasharray="10 10" />
					<path d="M58 184C31.5 162.5 19 153 -32 153" stroke="#2C3844" stroke-opacity="0.6" stroke-dasharray="10 10" />
					<path d="M1001 228C974.5 249.5 962 259 911 259" stroke="#2C3844" stroke-opacity="0.6" stroke-dasharray="10 10" />
					<path d="M1376 159C1402.5 137.5 1415 128 1466 128" stroke="#2C3844" stroke-opacity="0.6" stroke-dasharray="10 10" />
				</svg>
				<div class="container">
					<div class="level">
						<div class="level-content">
							<div class="level-title">Level 1</div>
							<div class="level-text">
								Our first line support team is readily available via chat, telephone, and email to resolve your basic technical issues
								quickly and efficiently.
							</div>
						</div>
					</div>
					<div class="level">
						<div class="level-content">
							<div class="level-title">Level 2</div>
							<div class="level-text">
								Our experienced L2 support engineers have access to server back panels, allowing them to troubleshoot and resolve more
								complex issues without the need for additional tickets. They also implement preventive measures to minimize future problems.
							</div>
						</div>
					</div>
					<div class="level">
						<div class="level-content">
							<div class="level-title">Level 3</div>
							<div class="level-text">
								Our elite L3 support team is equipped with the skills and knowledge to tackle even the most challenging server-side
								problems. They are not only capable of resolving issues but can also set up your IT infrastructure from scratch.
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- <section class="section default-section">
			<div class="container">
				<article class="article">
					<p>Welcome to AV DEVS Remote Tech Support Support Services</p>
					<p>
						At AV DEVS, we pride ourselves on being your trusted IT partner, helping your business navigate the digital
						landscape with confidence. Our comprehensive IT support services are tailored to meet your organization's unique
						needs, enabling you to thrive in an increasingly digital world. Let us handle the technical challenges so you can
						focus on growing your business.
					</p>
					<h2>Our Services</h2>
					<p>We provide a wide range of IT support services, including but not limited to:</p>
					<ul>
						<li>Windows Servers & Internal Servers</li>
						<li>Documentum</li>
						<li>Linux Servers</li>
						<li>Database Management Systems</li>
						<li>Server Maintenance</li>
					</ul>
					<p>
						Our dedicated team of remote tech support engineers is capable of growing from 1 to 6 resources, ensuring that we
						can ramp up our support capabilities as per your business requirements.
					</p>
					<h3>Why Choose AV DEVS Tech Support?</h3>
					<ul>
						<li>Close 20-30 tickets per technician per day</li>
						<li>Achieve a 100% SLA</li>
						<li>Resolve over 10,000+ tickets</li>
					</ul>
					<h3>Three Levels of Expert Support</h3>
					<p>To cater to the diverse needs of our clients, we offer three levels of support:</p>
					<h4>Level 1</h4>
					<p>
						Our first line support team is readily available via chat, telephone, and email to resolve your basic technical
						issues quickly and efficiently.
					</p>
					<h4>Level 2</h4>
					<p>
						Our experienced L2 support engineers have access to server back panels, allowing them to troubleshoot and resolve
						more complex issues without the need for additional tickets. They also implement preventive measures to minimize
						future problems.
					</p>
					<h4>Level 3</h4>
					<p>
						Our elite L3 support team is equipped with the skills and knowledge to tackle even the most challenging server-side
						problems. They are not only capable of resolving issues but can also set up your IT infrastructure from scratch.
					</p>
				</article>
			</div>
		</section> -->
	</main>
</template>

<script>
import { mapActions } from "vuex";
import Subtitle from "@/components/utils/Subtitle.vue";
// import Title from "@/components/utils/Title.vue";

export default {
	name: "RemoteTechSupport",
	components: { Subtitle },
	metaInfo: {
		title: "The only technology partner you will ever need | Enterprise Solutions",
		meta: [
			{
				name: "description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
			{
				name: "og:title",
				content: "The only technology partner you will ever need | Enterprise Solutions",
			},
			{
				name: "og:description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
		],
	},
	methods: {
		...mapActions(["fillTargets"]),
	},
	mounted() {
		this.fillTargets();
	},
};
</script>

<style lang="scss" scoped>
.hero-section {
	background: url("~@/assets/images/about-bg.webp") center/cover;
}

.title {
	margin-bottom: 0.5em;
}

.services-section {
	padding: 50px 0;
	background-color: $white;
}

.choose-us-section {
	background-color: $secondary-color;
	padding-bottom: 70px;
	.title {
		color: $white;
	}

	.section-head {
		margin-bottom: 20px;
	}

	.reasons {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		place-items: center;
		gap: min(80px, 2vw);

		@media screen and (max-width: 992px) {
			grid-template-columns: repeat(1, 1fr);
		}

		.reason {
			display: flex;
			align-items: center;
			gap: 2rem;

			&-icon {
				.icon {
					width: 80px;
					height: 80px;

					img {
						width: 100%;
						height: 100%;
					}

					@media screen and (max-width: 1200px) {
						width: 60px;
						height: 60px;
					}
				}
			}

			&-title {
				font-size: 1.875rem;
				font-weight: 900;
				color: $white;
				margin-bottom: 4px;

				@media screen and (max-width: 1200px) {
					font-size: 1.5rem;
				}
			}

			p {
				color: #b4c2d0;
			}

			&.highlight {
				background-color: #3e4d5c;
				padding: 40px;
				width: 100%;
				justify-content: center;

				@media screen and (max-width: 992px) {
					width: auto;
				}
			}
		}
	}
}

.expert-section {
	padding-bottom: 120px;
}

.levels {
	svg {
		width: 100%;

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	.level-content {
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.level-title {
		color: $primary-color;
		font-size: 1.875rem;
		font-weight: 700;
		margin-bottom: 0.5em;
	}
	.level-text {
		// font-size: 0.875rem;
		color: $text-dark;
	}

	.level {
		display: none;
		background-color: $white;
		box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
		padding: 40px;
		max-width: 80%;
		margin: 0 auto;
		margin-bottom: 20px;

		@media screen and (max-width: 1200px) {
			display: block;
		}

		@media screen and (max-width: 768px) {
			max-width: none;
		}
	}
}
</style>
