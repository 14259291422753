<template>
	<h4 class="subtitle"><slot></slot></h4>
</template>

<script>
export default {
	name: "Subtitle",
};
</script>

<style lang="scss" scoped>
.subtitle {
	font-size: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
	color: $primary-color;
	margin-bottom: 1rem;

	@media screen and (max-width: 992px) {
		font-size: 1rem;
	}
}
</style>
